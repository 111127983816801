import React from "react";
import styled from "styled-components";

// assets
import CloseIcon from "../../assets/Icons/x.svg";
import LinkIcon from "../../assets/Icons/linkexternal.svg"
import Uniswap from "../../assets/Icons/uniswap.png"
import Cowswap from "../../assets/Icons/cowswap.jpg"
import Inch from "../../assets/Icons/1inch.png"
import Kyber from "../../assets/Icons/kyber.png"

export default function SwapModal({ isOpen, onClose }) {
  if (!isOpen) {
    return null;
  }
  return (
    <ModalWrapper>
      <ModalContent>
        <h2>Buy ELON</h2>
        <CloseButton onClick={onClose}>
          <img src={CloseIcon} alt="close modal" />
        </CloseButton>
        <SwapOptions>
          <ul>
            <a
              href="https://app.uniswap.org/#/swap?inputCurrency=eth&outputCurrency=0x761d38e5ddf6ccf6cf7c55759d5210750b5d60f3"
              target="_blank"
              rel="noreferrer"
            >
              <li>
                <div>
                  <img src={Uniswap} alt="uniswap" />
                  <div>
                    <h3>Uniswap</h3>
                  </div>
                </div>
                <img
                  src={LinkIcon}
                  alt="External Link Icon"
                />
              </li>
            </a>
            <a
              href="https://swap.cow.fi/#/1/swap/ETH/ELON"
              target="_blank"
              rel="noreferrer"
            >
              <li>
                <div>
                  <img src={Cowswap} alt="Curve" />
                  <div>
                    <h3>Cowswap</h3>
                  </div>
                </div>
                <img
                  src={LinkIcon}
                  alt="External Link Icon"
                />
              </li>
            </a>
            <a
              href="https://app.1inch.io/#/1/simple/swap/ETH/ELON"
              target="_blank"
              rel="noreferrer"
            >
              <li>
                <div>
                  <img src={Inch} alt="1inch exchange" />
                  <div>
                    <h3>1inch</h3>
                  </div>
                </div>
                <img
                  src={LinkIcon}
                  alt="External Link Icon"
                />
              </li>
            </a>
            <a
              href="https://kyberswap.com/swap/ethereum?inputCurrency=ETH&outputCurrency=0x761D38e5ddf6ccf6Cf7c55759d5210750B5D60F3"
              target="_blank"
              rel="noreferrer"
            >
              <li>
                <div>
                  <img src={Kyber} alt="Kyber Swap" />
                  <div>
                    <h3>Kyber</h3>
                  </div>
                </div>
                <img
                  src={LinkIcon}
                  alt="External Link Icon"
                />
              </li>
            </a>
           
          </ul>
        </SwapOptions>
        <Disclaimer>
          Links to third-party services are provided for your convenience. We
          are not responsible for their operation.
        </Disclaimer>
      </ModalContent>
    </ModalWrapper>
  );
}

// styles

const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.8);
  z-index: 1000;
  padding: 20px;
`;

const ModalContent = styled.div`
  position: relative;
  background: rgba(0, 0, 0, 0.9);
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 20px;
  border-radius: 12px;
  max-width: 500px;
  width: 100%;
  & h2 {
    color: #ffffff;
  }
`;

const CloseButton = styled.div`
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
  transition: 0.3s;
  &:hover {
    opacity: 0.7;
  }
  & img {
    width: 35px;
    height: auto;
  }
`;

const SwapOptions = styled.div`
  & ul {
    list-style: none;
    margin: 24px 0 0;
    padding: 0;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 12px;
    & a {
      text-decoration: none;
      transition: 0.2s;
      &:hover {
        opacity: 0.7;
      }
      & li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        & img {
            filter: brightness(0) invert(1);
            opacity: 0.7;
        }
        & div {
          display: flex;
          align-items: center;
          color: #ffffff;
          & img {
            border-radius: 8px;
            margin-right: 20px;
            height: 48px;
            width: 48px;
            filter: none;
            opacity: 1;
          }
        }
      }
    }
  }
`;

const Disclaimer = styled.p`
  color: #fff;
  opacity: 0.7;
  margin-top: 20px;
  text-align: center;
  font-size: 14px;
`;
