import { styled } from "styled-components";
import { useEffect, useState } from "react";
import { Tooltip } from "react-tooltip";

// web 3
import {
  usePrepareContractWrite,
  useContractWrite,
  useContractRead,
  useAccount,
  useWaitForTransaction,
  useNetwork,
  useSwitchNetwork,
} from "wagmi";
import { ethers } from "ethers";
import sfrxConfig from "../../../web3/abis/sfrxETH.json";
import frxConfig from "../../../web3/abis/frxETH.json";
// import testSfrxConfig from "../../../web3/testnetabis/testSFRX.json";
// import testFrxConfig from "../../../web3/testnetabis/testFrxETH.json";
import { useWeb3Modal } from "@web3modal/react";

// components
import WithdrawButton from "./withdrawButton";
import Modal from "./modal";
import SwapModal from "./swapmodal";

// assets
import Icon from "../../../assets/Icons/sfrxETH.svg";
import { RiseLoader } from "react-spinners";
import Info from "../../../assets/Icons/info.svg";
import FrxIcon from "../../../assets/Icons/frxETH.svg";
import EthIcon from "../../../assets/Icons/ethicon.svg";

function WithdrawInput() {
  const { open } = useWeb3Modal();
  const { isConnected, address } = useAccount();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSwapModalOpen, setIsSwapModalOpen] = useState(false);
  const { chain } = useNetwork();
  const { switchNetwork } = useSwitchNetwork();

  const isNotMainnet = chain?.id !== 1;

  const handleSwitch = () => {
    switchNetwork?.(1);
  };

  const sfrxContract = {
    address: process.env.REACT_APP_SFRX_CONTRACT_ADDRESS,
    abi: sfrxConfig,
  };
  const frxContract = {
    address: process.env.REACT_APP_FRX_CONTRACT_ADDRESS,
    abi: frxConfig,
  };
  // const sfrxContract = {
  //   address: testSfrxConfig.address,
  //   abi: testSfrxConfig.abi,
  // };
  // const frxContract = {
  //   address: testFrxConfig.address,
  //   abi: testFrxConfig.abi,
  // };

  // sfrxETH contract read for user balance
  const { data: balanceData } = useContractRead({
    ...sfrxContract,
    functionName: "balanceOf",
    args: [address],
    watch: true,
  });

  const { data: frxBalanceData } = useContractRead({
    ...frxContract,
    functionName: "balanceOf",
    args: [address],
    watch: true,
  });

  // user input value
  const [inputAmount, setInputAmount] = useState("0");

  const { config } = usePrepareContractWrite({
    ...sfrxContract,
    functionName: "redeem",
    args: [
      inputAmount ? ethers.utils.parseEther(inputAmount).toString() : "0",
      address,
      address,
    ],
  });

  // console.log("Parsed Ether Value:", ethers.utils.parseEther(inputAmount))

  const {
    write,
    isLoading,
    isSuccess,
    isError,
    error,
    data: writeData,
  } = useContractWrite(config);

  const {
    isLoading: txLoading,
    isSuccess: txSuccess,
    isError: txError,
    error: txErrorMessage,
  } = useWaitForTransaction({
    hash: writeData,
  });

  let originalSfrxBalance = balanceData
    ? ethers.utils.formatEther(balanceData)
    : null;
  let sfrxBalance = originalSfrxBalance
    ? parseFloat(originalSfrxBalance.toString().slice(0, 6))
    : null;

  let frxBalance = frxBalanceData
    ? ethers.utils.formatEther(frxBalanceData)
    : null;
  frxBalance = frxBalance
    ? parseFloat(frxBalance.toString().slice(0, 6))
    : null;

  const displayBalance = () => {
    if (!isConnected) return "Please connect wallet";
    if (sfrxBalance === null) return "0.0000";
    return sfrxBalance + " sfrxETH";
  };

  const handleAmountChange = (event) => {
    const value = event.target.value;
    if (value === "" || value.trim() === "" || parseFloat(value) < 0) {
      setInputAmount("");
    } else {
      setInputAmount(value);
    }
  };

  const handleWithdrawButtonOnClick = () => {
    if (isNotMainnet) {
      handleSwitch();
    } else if (
      config &&
      handleWithdraw &&
      parseFloat(inputAmount) <= originalSfrxBalance
    ) {
      handleWithdraw();
    } else {
      console.log(
        "Something went wrong, config or handleWithdraw is not defined, or inputAmount is greater than sfrxBalance."
      );
    }
  };

  const handleWithdraw = () => {
    // Validate input amount
    const validatedAmount = parseFloat(inputAmount);
    if (
      isNaN(validatedAmount) ||
      validatedAmount > originalSfrxBalance ||
      inputAmount === ""
    ) {
      return;
    }
    write(config);
  };

  // Transaction Modal
  useEffect(() => {
    if (txSuccess || isError || txError) {
      setIsModalOpen(true);
    }
  }, [txSuccess, txError, isError]);

  // Max Withdraw
  const handleMaxClick = () => {
    setInputAmount(originalSfrxBalance.toString());
  };
  

  // Swap Modal
  const toggleSwapModal = () => {
    setIsSwapModalOpen(!isSwapModalOpen);
  };

  return (
    <>
      <StakingContainer>
        <ReceiveContainer>
          <BottomInfo>
            <InfoText>
              Your Staked Amount:{" "}
              <img
                src={Info}
                alt="additional info"
                data-tooltip-id="staking-tooltip"
                data-tooltip-variant="info"
                data-tooltip-content="This reflects your total sfrxETH stake, not accruing yields. Rewards are realized upon withdrawal, converting sfrxETH back to frxETH."
              />
            </InfoText>
          </BottomInfo>
          <BottomInfo>
            <StakedAmount>{displayBalance()}</StakedAmount>
          </BottomInfo>
        </ReceiveContainer>
        <InputDiv>
          <TopInfoContainer>
            <TransactionContainer>
              <Input
                type="number"
                placeholder="0.0"
                step="0.01"
                min="0"
                value={inputAmount}
                onChange={handleAmountChange}
              />
              <MaxButton
                onClick={handleMaxClick}
                disabled={!isConnected || sfrxBalance === null}
              >
                MAX
              </MaxButton>
              <AssetContainer>
                <img src={Icon} alt="Ethereum Icon" />
                <span>sfrxETH</span>
              </AssetContainer>
            </TransactionContainer>
            <MaxButtonStacked
              onClick={handleMaxClick}
              disabled={!isConnected || sfrxBalance === null}
            >
              MAX
            </MaxButtonStacked>
            {sfrxBalance === 0 && (
              <ErrorMessage>
                You have no sfrxETH in your wallet to withdraw.
              </ErrorMessage>
            )}
            {sfrxBalance > 0 && inputAmount > originalSfrxBalance && (
              <ErrorMessage>
                You must enter a quantity less than the {originalSfrxBalance} sfrxETH in
                your wallet.
              </ErrorMessage>
            )}
          </TopInfoContainer>
        </InputDiv>
        <WithdrawButton
          onClick={isConnected ? handleWithdrawButtonOnClick : open}
          btntext={
            isLoading || txLoading ? (
              <RiseLoader color="#ffffff" size={8} speedMultiplier={1} />
            ) : isConnected ? (
              isNotMainnet ? (
                "Switch Networks"
              ) : (
                "Withdraw"
              )
            ) : (
              "Connect Wallet"
            )
          }
          disabled={txLoading || parseFloat(inputAmount) > originalSfrxBalance || originalSfrxBalance === 0}
        />
      </StakingContainer>
      {frxBalance > 0 && (
        <SwapContainer>
          <SwapDiv>
            <FRXbalance>
              <BalanceText>You have {frxBalance} frxETH</BalanceText>
              <img src={FrxIcon} alt="Frx Icon" />
            </FRXbalance>
            <div onClick={toggleSwapModal}>
              <SwapButton>
                Swap to <img src={EthIcon} alt="ETH" />
              </SwapButton>
            </div>
          </SwapDiv>
        </SwapContainer>
      )}
      <StyledTooltip id="staking-tooltip" />
      <SwapModal isOpen={isSwapModalOpen} onClose={toggleSwapModal} />
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        isSuccess={isSuccess}
        isError={isError}
        txError={txError}
        data={writeData}
        errorMessage={error?.message}
        txErrorMessage={txErrorMessage?.message}
      />
    </>
  );
}

export default WithdrawInput;

// styles

const StyledTooltip = styled(Tooltip)`
  max-width: 200px;
  font-size: 12px;
  background: rgba(0, 0, 0, 0.9);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 10px;
`;

// Top

const StakingContainer = styled.div`
  color: #ffffff;
  font-weight: 500;
`;

const InputDiv = styled.div`
  background-color: rgba(0, 66, 102, 1);
  border-radius: 12px;
  padding: 16px;
  margin-bottom: 8px;
`;

const TopInfoContainer = styled.div``;

const InfoText = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  opacity: 0.7;
  & img {
    width: 16px;
    height: 16px;
    cursor: pointer;
    filter: brightness(0) invert(1);
    margin-left: 5px;
  }
`;

const StakedAmount = styled.span`
  font-size: 24px;
  font-weight: 600;
`;

// Transaction Input

const TransactionContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 12px 0;
  background-color: #000000;
  border-radius: 12px;
`;

const Input = styled.input`
  font-size: 20px;
  border-radius: 12px 0 0 12px;
  border-top: 1px solid #00a6ff;
  border-bottom: 1px solid #00a6ff;
  border-left: 1px solid #00a6ff;
  border-right: none;
  height: 56px;
  min-width: 150px;
  width: 100%;
  padding: 0 16px;
  background-color: #000000;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  color: #ffffff;
  outline: none;
  @media screen and (min-width: 600px) {
    width: 70%;
  }
  &[type="number"] {
    -moz-appearance: textfield;
  }
  &[type="number"]::-webkit-inner-spin-button,
  &[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const MaxButton = styled.div`
  background-color: transparent;
  border-top: 1px solid #00a6ff;
  border-bottom: 1px solid #00a6ff;
  border-left: none;
  border-right: none;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 56px;
  padding: 0 8px;
  transition: 0.2s;
  @media (max-width: 438px) {
    display: none;
  }
  &:hover {
    color: gray;
  }
`;

const MaxButtonStacked = styled(MaxButton)`
  display: none;
  margin-top: 15px;
  background-color: transparent;
  border-top: none;
  border-bottom: none;
  border-left: none;
  border-right: none;
  color: #ffffff;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  height: 10px;
  padding: 0 8px;
  transition: 0.2s;
  @media (max-width: 438px) {
    display: flex;
  }
`;

const AssetContainer = styled.div`
  flex: 1;
  height: 56px;
  width: auto;
  border: 1px solid #00a6ff;
  border-radius: 0 12px 12px 0;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
  & img {
    width: 24px;
    height: 24px;
    margin-right: 10px;
    @media only screen and (max-width: 386px) {
      width: 21px;
      height: 21px;
      margin-right: 5px;
    }
    @media only screen and (max-width: 370px) {
      display: none;
    }
  }
  & span {
    font-size: 18px;
    font-weight: 500;
    @media only screen and (max-width: 386px) {
      font-size: 16px;
    }
  }
`;

const ErrorMessage = styled.span`
  display: flex;
  justify-content: center;
  font-size: 14px;
  color: #ffffff;
  text-align: center;
`;

// Receive

const ReceiveContainer = styled.div`
  background-color: rgba(0, 66, 102, 1);
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
  border-radius: 12px;
  padding: 16px;
`;

const BottomInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
`;

// Swap frxEth to ETH

const SwapContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  margin-top: 10px;
  padding: 10px 0;
`;

const SwapDiv = styled.div`
  text-decoration: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
  & a {
    text-decoration: none;
  }
  @media only screen and (max-width: 387px) {
    flex-direction: column;
    gap: 10px;
  }
`;

const FRXbalance = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #ffffff;
  & img {
    width: 25px;
    height: auto;
    margin-left: 10px;
  }
  @media only screen and (max-width: 438px) {
    font-size: 13px;
    & img {
      margin-left: 5px;
    }
  }
`;

const BalanceText = styled.span`
  font-weight: 600;
  font-size: 18;
`;

const SwapButton = styled.button`
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1e90ff;
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  padding: 8px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: #1c86ee;
  }
  & img {
    width: 25px;
    height: auto;
    margin-left: 5px;
  }
  @media only screen and (max-width: 438px) {
    padding: 5px 15px;
    font-size: 14px;
  }
`;
