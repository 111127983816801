import styled from "styled-components";

// web3
import {
  useNetwork,
  useSwitchNetwork,
  useAccount,
  useContractRead,
} from "wagmi";
import { Web3Button } from "@web3modal/react";
import { ethers } from "ethers";
import elonConfig from "../../web3/abis/dogelon.json";

// assets
import Logo from "../../assets/Logo/logo.svg";
import Discord from "../../assets/Icons/discord.svg";
// import ErrorIcon from "../../assets/Logo/wrongnetwork.png"

function Header() {
  const { chain } = useNetwork();
  const { isConnected, address } = useAccount();
  const { switchNetwork } = useSwitchNetwork();

  const isNotMainnet = chain?.id !== 1;

  const handleSwitch = () => {
    switchNetwork?.(1);
  };

  // DogelonRead

  const elonContract = {
    address: process.env.REACT_APP_ELON_CONTRACT_ADDRESS,
    abi: elonConfig,
  };

  const { data: elonBalanceData } = useContractRead({
    ...elonContract,
    functionName: "balanceOf",
    args: [address],
    watch: true,
  });

  let elonBalanceNum =
    elonBalanceData !== undefined
      ? ethers.utils.formatEther(elonBalanceData.toString()).split(".")[0]
      : "0";

  return (
    <HeaderContainer>
      <Container>
        <Img src={Logo} alt="Dogelon Mars Eth Staking" />
        <Web3Button
          style={{
            margin: "30px 0px 0px 0px",
            background: "#000000",
            opacity: "1",
            borderRadius: "10px",
          }}
          balance="show"
          icon="hide"
        />
        {isConnected && isNotMainnet && (
          <AlertContainer onClick={handleSwitch}>
            <span>Wrong network detected: click here.</span>
          </AlertContainer>
        )}
        {isConnected && !isNotMainnet && (
          <a
            href="https://discord.com/invite/dogelonmars"
            target="_blank"
            rel="noreferrer"
          >
            <AlertContainer
              backgroundColor={
                parseInt(elonBalanceNum) >= 1000000000
                  ? "rgba(76, 175, 80, 0.7)"
                  : "rgba(88, 101, 242, 0.7)"
              }
            >
              <span>
                {parseInt(elonBalanceNum) >= 1000000000
                  ? "You're eligible for extra rewards! Learn more on Discord"
                  : "Earn additional rewards by holding 1B ELON. Learn more on Discord"}
                <img src={Discord} alt="Join Discord" />
              </span>
            </AlertContainer>
          </a>
        )}
      </Container>
    </HeaderContainer>
  );
}

export default Header;

// styles

const HeaderContainer = styled.div`
  margin: 0;
  padding-top: 50px;
  @media only screen and (max-width: 620px) {
    padding-top: 55px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 582px) {
    padding: 0 15px;
  }
  & a {
    text-decoration: none;
  }
`;

const Img = styled.img`
  max-width: 100%;
  height: auto;
`;

const AlertContainer = styled.div`
  cursor: pointer;
  margin-top: 20px;
  padding: 8px;
  background-color: ${(props) => props.backgroundColor || "rgba(0, 0, 0, 0.7)"};
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  transition: 0.2s;
  & img {
    width: 30px;
    height: auto;
  }
  &:hover {
    opacity: 0.7;
  }
  & span {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    text-align: center;
    @media only screen and (max-width: 583px) {
      display: block;
      text-align: left;
    }
    & img {
      height: 20px;
      width: auto;
      margin: 0 3px;
      @media only screen and (max-width: 583px) {
        margin-bottom: -4px;
      }
    }
  }
`;
