import React from "react";
import styled from "styled-components";

// assets
import CloseIcon from "../../../assets/Icons/x.svg";
import LinkIcon from "../../../assets/Icons/linkexternal.svg"
import CowSwap from "../../../assets/Icons/cowswap.jpg"
import Uniswap from "../../../assets/Icons/uniswap.png"
import Curve from "../../../assets/Icons/curvefi.png"
import Frax from "../../../assets/Icons/fraxicon.svg"


export default function SwapModal({ isOpen, onClose }) {
  if (!isOpen) {
    return null;
  }
  return (
    <ModalWrapper>
      <ModalContent>
        <h2>Swap</h2>
        <CloseButton onClick={onClose}>
          <img src={CloseIcon} alt="close modal" />
        </CloseButton>
        <SwapOptions>
          <ul>
            <a
              href="https://curve.fi/#/ethereum/pools/frxeth/swap"
              target="_blank"
              rel="noreferrer"
            >
              <li>
                <div>
                  <img src={Curve} alt="Curve" />
                  <div>
                    <h3>Curve.fi</h3>
                  </div>
                </div>
                <img
                  src={LinkIcon}
                  alt="External Link Icon"
                />
              </li>
            </a>
            <a
              href="https://app.frax.finance/swap/main?from=0x853d955aCEf822Db058eb8505911ED77F175b99e&to=native"
              target="_blank"
              rel="noreferrer"
            >
              <li>
                <div>
                  <img src={Frax} alt="Frax" />
                  <div>
                    <h3>Frax Finance</h3>
                  </div>
                </div>
                <img
                  src={LinkIcon}
                  alt="External Link Icon"
                />
              </li>
            </a>
            <a
              href="https://swap.cow.fi/#/1/swap/frxETH/ETH"
              target="_blank"
              rel="noreferrer"
            >
              <li>
                <div>
                  <img src={CowSwap} alt="CowSwap" />
                  <div>
                    <h3>CowSwap</h3>
                  </div>
                </div>
                <img
                  src={LinkIcon}
                  alt="External Link Icon"
                />
              </li>
            </a>
            <a
              href="https://app.uniswap.org/#/swap?inputCurrency=0x5E8422345238F34275888049021821E8E08CAa1f&outputCurrency=ETH"
              target="_blank"
              rel="noreferrer"
            >
              <li>
                <div>
                  <img src={Uniswap} alt="Uniswap" />
                  <div>
                    <h3>Uniswap</h3>
                  </div>
                </div>
                <img
                  src={LinkIcon}
                  alt="External Link Icon"
                />
              </li>
            </a>
          </ul>
        </SwapOptions>
        <Disclaimer>
          Links to third-party services are provided for your convenience. We
          are not responsible for their operation.
        </Disclaimer>
      </ModalContent>
    </ModalWrapper>
  );
}

// styles

const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.8);
  z-index: 1000;
  padding: 20px;
`;

const ModalContent = styled.div`
  position: relative;
  background: rgba(0, 0, 0, 0.9);
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 20px;
  border-radius: 12px;
  max-width: 500px;
  width: 100%;
  & h2 {
    color: #ffffff;
  }
`;

const CloseButton = styled.div`
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
  transition: 0.3s;
  &:hover {
    opacity: 0.7;
  }
  & img {
    width: 35px;
    height: auto;
  }
`;

const SwapOptions = styled.div`
  & ul {
    list-style: none;
    margin: 24px 0 0;
    padding: 0;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 12px;
    & a {
      text-decoration: none;
      transition: 0.2s;
      &:hover {
        opacity: 0.7;
      }
      & li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        & img {
            filter: brightness(0) invert(1);
            opacity: 0.7;
        }
        & div {
          display: flex;
          align-items: center;
          color: #ffffff;
          & img {
            border-radius: 8px;
            margin-right: 20px;
            height: 48px;
            width: 48px;
            filter: none;
            opacity: 1;
          }
        }
      }
    }
  }
`;

const Disclaimer = styled.p`
  color: #fff;
  opacity: 0.7;
  margin-top: 20px;
  text-align: center;
  font-size: 14px;
`;
