import { useState } from 'react';
import { styled } from "styled-components";

// components
import StakingInput from "./StakingInput";
import WithdrawInput from "./WithdrawInput";

// assets
import FraxIcon from "../../assets/Icons/fraxicon.svg"

function StakingInterface() {
    const [selectedTab, setSelectedTab] = useState('Mint & Stake');

    return (
        <>
            <CardWrapper>
                <Container>
                    <Card>
                        <TabList>
                            <Tab onClick={() => setSelectedTab('Mint & Stake')}
                                style={{ opacity: selectedTab === 'Mint & Stake' ? 1 : 0.7 }}
                            >
                                Mint & Stake
                            </Tab>
                            <Tab onClick={() => setSelectedTab('Current Stake')}
                                style={{ opacity: selectedTab === 'Current Stake' ? 1 : 0.7 }}
                            >
                                Current Stake
                            </Tab>
                        </TabList>
                        {selectedTab === 'Mint & Stake' ? <StakingInput /> : <WithdrawInput />}
                    </Card>
                </Container>
            </CardWrapper>
            
            <FraxDiv href="https://frax.finance/" target="_blank" rel="noreferrer">
                <span>Powered by</span>
                <img src={FraxIcon} alt="Frax"/>
                <span>Frax Finance</span>
            </FraxDiv>
            
        </>
    )
}

export default StakingInterface

// styles

const CardWrapper = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 30px;
`

const Container = styled.div`
    flex-basis: 552px;
    display: flex;
    flex-direction: column;
    row-gap: 32px;
`

const Card = styled.div`
    padding: 24px;
    background-color: rgba(0, 0 , 0, 0.8);
    border-radius: 12px;
    border: 1px solid rgba(255, 255, 255, 0.1);
`

// top tabs

const TabList = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;
`

const Tab = styled.a`
    cursor: pointer;
    color: #ffffff;
    opacity: 0.7;
    font-weight: 500;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    font-size: 18px;
    margin: 4;
    line-height: 24px;
    user-select: none;
    &:hover {
        text-decoration: underline;
        text-decoration-thickness: 2px;
        text-underline-offset: 5px;
    }
`

// Frax

const FraxDiv = styled.a`
    text-decoration: none;
    cursor: pointer;
    margin-top: 10px;
    margin-bottom: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    @media only screen and (max-height: 776px) {
        margin-bottom: 30px;
    }
    & span {
        color: #ffffff;
        font-size: 16px;
    }
    & img {
        margin: 0 5px;
    }
`
