import styled from "styled-components";

function MintButton( props ) {
  return (
    <ButtonContainer>
      <button onClick={props.onClick} disabled={props.disabled}>{props.btntext}</button>
    </ButtonContainer>
  )
}

export default MintButton;

// Button

const ButtonContainer = styled.div`
    margin-top: 24px;
    display: flex;
    & button {
        cursor: pointer;
        background-color: #FF0000;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        height: 60px;
        width: 100%;
        color: #ffffff;
        font-weight: 500;
        font-size: 19px;
        padding: 0 24px;
        border-radius: 12px;
        transition: 0.4s;
        &:hover {
          opacity: 0.7;
        }
        &:disabled {
          cursor: default;
        }
    }
`;