import { styled } from "styled-components";
import { useState, useEffect } from "react";
import { Tooltip } from "react-tooltip";

// web3
import {
  usePrepareContractWrite,
  useContractWrite,
  useAccount,
  useBalance,
  useWaitForTransaction,
  useNetwork,
  useSwitchNetwork,
} from "wagmi";
import frxConfig from "../../../web3/abis/frxETHMinter.json";
// import testConfig from "../../../web3/testnetabis/testFrxETHMinter.json";
import { ethers } from "ethers";
import { useWeb3Modal } from "@web3modal/react";

// components
import MintButton from "./mintButton";
import Modal from "./modal";

// assets
import EthIcon from "../../../assets/Icons/ethicon.svg";
import Arrow from "../../../assets/Icons/arrowdown.svg";
import FraxIcon from "../../../assets/Icons/fraxicon.svg";
import Info from "../../../assets/Icons/info.svg";
import { RiseLoader, BeatLoader } from "react-spinners";

function StakingInput() {
  const { open } = useWeb3Modal();
  const [apr, setApr] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { isConnected, address } = useAccount();
  const [inputAmount, setInputAmount] = useState(0);
  const [hasInput, setHasInput] = useState(false);
  const [conversionRate, setConversionRate] = useState(0);
  const [receiveAmount, setReceiveAmount] = useState(0);
  const { chain } = useNetwork();
  const { switchNetwork } = useSwitchNetwork();

  const isNotMainnet = chain?.id !== 1;

  const frxContract = {
    address: process.env.REACT_APP_FRXMINTER_CONTRACT_ADDRESS,
    abi: frxConfig,
  }
  // const frxContract = {
  //   address: testConfig.address,
  //   abi: testConfig.abi,
  // };

  const { config } = usePrepareContractWrite({
    ...frxContract,
    functionName: "submitAndDeposit",
    args: [address],
    value: inputAmount
      ? ethers.utils.parseEther(inputAmount.toString()).toString()
      : "0",
  });

  const {
    write,
    error,
    isLoading,
    isError,
    isSuccess,
    data: writeData,
  } = useContractWrite(config);

  const {
    isLoading: txLoading,
    isSuccess: txSuccess,
    isError: txError,
    error: txErrorMessage,
  } = useWaitForTransaction({
    hash: writeData,
  });

  const mintButtonOnClickHandler = () => {
    if (isNotMainnet) {
      switchNetwork?.(1);
    } else if (config && write && inputAmount !== 0) {
      write();
    }
  };

  // Transaction Modal
  useEffect(() => {
    if (txSuccess || isError || txError) {
      setIsModalOpen(true);
    }
  }, [txSuccess, txError, isError]);

  const { data: userBalance } = useBalance({
    address: address,
  });

  const handleInputChange = (event) => {
    let value = event.target.value;
    if (value === "") {
      setInputAmount("");
      setReceiveAmount(0);
      setHasInput(false);
    } else {
      value = Number(value);
      setInputAmount(value);
      setReceiveAmount(value / conversionRate);
      setHasInput(true);
    }
  };

  useEffect(() => {
    fetch("https://api.frax.finance/v2/frxeth/summary/latest")
      .then((response) => response.json())
      .then((data) => {
        setApr(data.sfrxethApr);
        setConversionRate(data.sfrxethFrxethPrice);
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    <>
      <StakingContainer>
        <InputDiv>
          <TopInfoContainer>
            <TopInfo>
              <InfoText>From</InfoText>
              <InfoText>
                APR:{" "}
                <StyledText>
                  {apr ? (
                    `${apr.toFixed(2)}%`
                  ) : (
                    <BeatLoader color="#ffffff" size={4} speedMultiplier={1} />
                  )}
                  <img
                    src={Info}
                    alt="APR info"
                    data-tooltip-id="apr-tooltip"
                    data-tooltip-variant="info"
                    data-tooltip-content="Estimated APR from attestations, produced blocks, and tips."
                  />
                </StyledText>
              </InfoText>
            </TopInfo>
            <TransactionContainer>
              <Input
                type="number"
                placeholder="0.0"
                min="0"
                value={inputAmount}
                onChange={handleInputChange}
              />
              <AssetContainer>
                <img src={EthIcon} alt="Ethereum Icon" />
                <span>ETH</span>
              </AssetContainer>
            </TransactionContainer>
            {!isConnected && (
              <ErrorMessage>Please connect your wallet.</ErrorMessage>
            )}
            {isConnected &&
              !isNotMainnet &&
              userBalance?.formatted &&
              inputAmount > userBalance?.formatted && (
                <ErrorMessage>
                  You must enter a quantity less than the{" "}
                  {userBalance?.formatted} ETH in your wallet.
                </ErrorMessage>
              )}
            {hasInput && isNotMainnet && isConnected && (
              <ErrorMessage>Wrong network detected!</ErrorMessage>
            )}
          </TopInfoContainer>
        </InputDiv>
        <Divider>
          <img src={Arrow} alt="Arrow Down" />
        </Divider>
        <ReceiveContainer>
          <BottomInfo>
            <div>
              <InfoText>To</InfoText>
              <img src={FraxIcon} alt="Frax" />
              <StyledText>sfrxETH</StyledText>
            </div>
          </BottomInfo>
          <BottomInfo>
            <InfoText>
              You will receive:{" "}
              <StyledText>
                {receiveAmount.toFixed(5)} sfrxETH{" "}
                <img
                  src={Info}
                  alt="additional info"
                  data-tooltip-id="conversion-tooltip"
                  data-tooltip-variant="info"
                  data-tooltip-content="Please note that the amount of sfrxETH you will receive is an estimate based on the current conversion rate. The actual amount you receive might vary due to fluctuations in the conversion rate at the time of your transaction."
                />
              </StyledText>
            </InfoText>
          </BottomInfo>
        </ReceiveContainer>
        <MintButton
          onClick={isConnected ? mintButtonOnClickHandler : open}
          btntext={
            isLoading || txLoading ? (
              <RiseLoader color="#ffffff" size={8} speedMultiplier={1} />
            ) : isConnected ? (
              isNotMainnet ? (
                "Switch Networks"
              ) : (
                "Mint & Stake"
              )
            ) : (
              "Connect Wallet"
            )
          }
          disabled={txLoading || parseFloat(inputAmount) > parseFloat(userBalance?.formatted)}
        />
      </StakingContainer>
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        isSuccess={isSuccess}
        isError={isError}
        txError={txError}
        data={writeData}
        errorMessage={error?.message}
        txErrorMessage={txErrorMessage?.message}
      />
      <StyledTooltip id="conversion-tooltip" />
      <StyledTooltip id="apr-tooltip" />
    </>
  );
}

export default StakingInput;

// styles

const StyledTooltip = styled(Tooltip)`
  max-width: 200px;
  font-size: 12px;
  background: rgba(0, 0, 0, 0.7);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 10px;
`;

// Top

const StakingContainer = styled.div`
  color: #ffffff;
  font-weight: 500;
`;

const InputDiv = styled.div`
  background-color: rgba(0, 66, 102, 1);
  border-radius: 12px;
  padding: 16px;
  margin-bottom: 8px;
`;

const TopInfoContainer = styled.div``;

const TopInfo = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
`;

const InfoText = styled.span`
  font-size: 14px;
`;

const StyledText = styled.span`
  font-size: 21px;
  opacity: 1 !important;
  font-weight: 600;
  & img {
    width: 16px;
    height: 16px;
    cursor: pointer;
    filter: brightness(0) invert(1);
    margin-left: 5px;
  }
  @media only screen and (max-width: 434px) {
    font-size: 20px;
  }
`;

// Transaction Input

const TransactionContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 12px 0;
  background-color: #000000;
  border-radius: 12px;
`;

const Input = styled.input`
  font-size: 20px;
  border-radius: 12px 0 0 12px;
  border: 1px solid #00a6ff;
  height: 56px;
  min-width: 150px;
  width: 100%;
  padding: 0 16px;
  background-color: #000000;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  color: #ffffff;
  &:focus {
    outline: none;
    border-color: transparent;
    box-shadow: 0 0 0 2px #00a6ff;
  }
  @media screen and (min-width: 600px) {
    width: 70%;
  }
  &[type="number"] {
    -moz-appearance: textfield;
  }
  &[type="number"]::-webkit-inner-spin-button,
  &[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const ErrorMessage = styled.span`
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 14px;
  color: #ffffff;
`;

const AssetContainer = styled.div`
  flex: 1;
  height: 56px;
  width: auto;
  border-top: 1px solid #00a6ff;
  border-bottom: 1px solid #00a6ff;
  border-right: 1px solid #00a6ff;
  border-radius: 0 12px 12px 0;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
  & img {
    width: 24px;
    height: 24px;
    margin-right: 12px;
  }
  & span {
    font-size: 18px;
    font-weight: 500;
  }
`;

// Divider

const Divider = styled.div`
    display: flex;
    justify-content: center;
    align-items: center
    margin: auto;
    padding: 10px 0;
`;

// Receive

const ReceiveContainer = styled.div`
  background-color: rgba(0, 66, 102, 1);
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  border-radius: 12px;
  padding: 16px;
`;

const BottomInfo = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  & div {
    display: flex;
    flex-direction: row;
    align-items: center;
    & img {
      width: 22px;
      height: auto;
      margin: 0 5px;
    }
  }
`;
