import React, { useState } from "react";
import styled from "styled-components";

// assets
import CloseIcon from "../../../assets/Icons/x.svg";
import MetaMaskIcon from "../../../assets/Icons/metamask.png";
import Arrow from "../../../assets/Icons/externalarrow.svg";
import ErrorIcon from "../../../assets/Logo/failedtx.png";
import SuccssIcon from "../../../assets/Logo/successtx.png";

export default function Modal({
  isOpen,
  onClose,
  isSuccess,
  isError,
  txError,
  txErrorMessage,
  data,
  errorMessage,
}) {
  const [isErrorExpanded, setIsErrorExpanded] = useState(false);

  if (!isOpen) return null;

  const handleExpandError = () => {
    setIsErrorExpanded(!isErrorExpanded);
  };

  const handleAddToMetamask = () => {
    if (window.ethereum && window.ethereum.isMetaMask) {
      window.ethereum
        .request({
          method: "wallet_watchAsset",
          params: {
            type: "ERC20",
            options: {
              address: "0x5E8422345238F34275888049021821E8E08CAa1f",
              symbol: "frxETH",
              decimals: 18,
              image:
                "https://s2.coinmarketcap.com/static/img/coins/64x64/23225.png",
            },
          },
        })
        .then((success) => {
          if (success) {
            console.log("Transaction added to Metamask successfully");
          } else {
            console.log("Transaction failed to be added to Metamask");
          }
        })
        .catch((error) => {
          console.log("An error occurred:", error);
        });
    } else {
      console.log("Metamask extension not detected");
    }
  };

  return (
    <ModalWrapper>
      <ModalContent>
        <CloseButton onClick={onClose}>
          <img src={CloseIcon} alt="close modal" />
        </CloseButton>
        {isSuccess && (
          <SuccessDiv>
            <ModalImg src={SuccssIcon} alt="Success!" />
            <div>
              <h2>✅ Transaction Completed!</h2>
              <p>
                <a
                  href={`https://etherscan.io/tx/${data?.hash}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  View on Etherscan
                  <ArrowIcon src={Arrow} alt="Arrow" />
                </a>
              </p>
              <AddToMetamaskButton onClick={handleAddToMetamask}>
                <MetaMaskIconContainer src={MetaMaskIcon} alt="MetaMask" />
                Add frxETH
              </AddToMetamaskButton>
            </div>
          </SuccessDiv>
        )}
       {!isSuccess && txError && (
          <ErrorDiv>
            <div>
              <ModalImg src={ErrorIcon} alt="Error :(" />
              <div>
                <h2>Mission Abort: System Error</h2>
                <p>Please try again.</p>
                <ExpandErrorButton onClick={handleExpandError}>
                  {isErrorExpanded ? "Minimize Error" : "Expand Error"}
                </ExpandErrorButton>
              </div>
            </div>
            {isErrorExpanded && (
              <ErrorContainer>
                <ErrorText>{txErrorMessage}</ErrorText>
              </ErrorContainer>
            )}
          </ErrorDiv>
        )}
        {!isSuccess && isError && !txError && (
          <ErrorDiv>
            <div>
              <ModalImg src={ErrorIcon} alt="Error :(" />
              <div>
                <h2>Mission Abort: System Error</h2>
                <p>Please try again.</p>
                <ExpandErrorButton onClick={handleExpandError}>
                  {isErrorExpanded ? "Minimize Error" : "Expand Error"}
                </ExpandErrorButton>
              </div>
            </div>
            {isErrorExpanded && (
              <ErrorContainer>
                <ErrorText>{errorMessage}</ErrorText>
              </ErrorContainer>
            )}
          </ErrorDiv>
        )}
      </ModalContent>
    </ModalWrapper>
  );
}

// styles

const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.8);
  z-index: 1000;
  @media only screen and (max-width: 620px) {
    padding: 15px;
  }
`;

const ModalContent = styled.div`
  position: relative;
  background: rgba(0, 0, 0, 0.9);
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 30px 20px;
  border-radius: 12px;
  max-width: 620px;
  width: 100%;
  text-align: center;
  & div {
    & h2 {
      color: #ffffff;
      text-align: left;
      @media only screen and (max-width: 509px) {
        font-size: 21px;
      }
      @media only screen and (max-width: 382px) {
        font-size: 19px;
      }
    }
    & p {
      color: #ffffff;
      margin-top: 8px;
      opacity: 0.7;
      @media only screen and (max-width: 382px) {
        font-size: 13px;
      }
      & a {
        color: #ffffff;
      }
    }
  }
`;

const SuccessDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  @media only screen and (max-width: 555px) {
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
  & img {
    margin-right: 20px;
  }

  & div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    @media only screen and (max-width: 555px) {
      flex-direction: column;
      justify-content: center;
      text-align: center;
    }
    & h2 {
      color: #ffffff;
      text-align: left;
    }

    & p {
      color: #ffffff;
      margin-top: 8px;
      opacity: 0.7;

      & a {
        color: #ffffff;
      }
    }
  }
`;

const ErrorDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;

  & > div:first-child {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    @media only screen and (max-width: 555px) {
      flex-direction: column;
      justify-content: center;
      text-align: center;
    }
    & img {
      margin-right: 20px;
    }

    & > div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
    }
  }
`;

const CloseButton = styled.div`
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
  transition: 0.3s;
  &:hover {
    opacity: 0.7;
  }
  & img {
    width: 35px;
    height: auto;
  }
`;

const ExpandErrorButton = styled.button`
  background: transparent;
  border: none;
  font-size: 16px;
  color: #ff0000;
  cursor: pointer;
  font-weight: 500;
  margin-top: 10px;
  transition: 0.3s;
  &:hover {
    opacity: 0.7;
  }
`;

const ErrorContainer = styled.div`
  max-height: 150px;
  overflow-y: auto;
  margin-top: 10px;
  padding: 10px;
  &::-webkit-scrollbar {
    width: 8px; /* Width of the scrollbar */
  }

  &::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.1);
  }

  &::-webkit-scrollbar-thumb {
    background: #ff0000;
    opacity: 0.7;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555; /* Color when hovered over */
  }
`;


const ErrorText = styled.p`
  text-align: left;
  word-wrap: break-word;
`;

const ArrowIcon = styled.img`
  width: 18px;
  height: 18px;
  margin-left: 5px;
  @media only screen and (max-width: 339px) {
    display: none;
  }
`;

const MetaMaskIconContainer = styled.img`
  width: 18px;
  height: 18px;
  margin-right: 5px !important;
`;

const AddToMetamaskButton = styled.button`
  background: transparent;
  border: none;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  margin-top: 15px;
  cursor: pointer;
  transition: 0.3s;
  display: flex;
  align-items: center;
  &:hover {
    opacity: 0.7;
  }
`;

const ModalImg = styled.img`
  width: 200px;
  height: auto;
  @media only screen and (max-width: 555px) {
    width: 150px;
    margin-bottom: 10px;
`;

