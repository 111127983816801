import styled from "styled-components";

// components
import Header from "./components/Header";
import StakingInterface from "./components/StakingInterface";
import Footer from "./components/Footer";

// web3
import { EthereumClient, w3mConnectors } from "@web3modal/ethereum";
import { Web3Modal } from "@web3modal/react";
import { configureChains, createConfig, WagmiConfig } from "wagmi";
import { mainnet } from "wagmi/chains";
import { publicProvider } from "wagmi/providers/public";
import { infuraProvider } from 'wagmi/providers/infura'

const projectId = process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID;

// const { publicClient } = configureChains(chains, [infuraProvider({ apiKey: "0c40dc5feae84128b5be56af617ad062" }), w3mProvider({ projectId })]);
// const { publicClient } = configureChains([goerli], [publicProvider()])

const { publicClient } = configureChains(
  [mainnet],
  [infuraProvider({ apiKey: process.env.REACT_APP_INFURA_API_KEY }), publicProvider()],
  { pollingInterval: 15_000 },
);

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, version: 2}),
  publicClient,
});
const ethereumClient = new EthereumClient(wagmiConfig);

function App() {
  return (
    <>
      <WagmiConfig config={wagmiConfig}>
        <AppContainer>
          <Header />
          <StakingInterface />
        </AppContainer>
        <Footer />
      </WagmiConfig>
      <Web3Modal projectId={projectId} ethereumClient={ethereumClient} />
    </>
  );
}

export default App;

// styles

const AppContainer = styled.div`
  height: 100%;
  min-height: 100vh;
  margin: auto;
  display: flex;
  flex-direction: column;
`;
