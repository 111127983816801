import React, { useState } from "react";
import { styled } from "styled-components";

// components
import SwapModal from "./swapelon";

// assets
import Github from "../../assets/Icons/github.svg";
import Discord from "../../assets/Icons/discord.svg";
import Twitter from "../../assets/Icons/twitter.svg";
import Telegram from "../../assets/Icons/telegram.svg";

function Footer() {
  const [isSwapModalOpen, setIsSwapModalOpen] = useState(false);

  const toggleSwapModal = () => {
    setIsSwapModalOpen(!isSwapModalOpen);
  };

  return (
    <>
      <FooterWrapper>
        <FooterContainer>
          <SocialDiv>
            <a
              href="https://github.com/DogelonMars"
              target="_blank"
              rel="noreferrer"
            >
              <img src={Github} alt="Go to Github" />
            </a>
            <a
              href="https://discord.com/invite/dogelonmars"
              target="_blank"
              rel="noreferrer"
            >
              <img src={Discord} alt="Go to Discord" />
            </a>
            <a
              href="https://twitter.com/dogelonmars"
              target="_blank"
              rel="noreferrer"
            >
              <img src={Twitter} alt="Go to Twitter" />
            </a>
            <a href="https://t.me/dogelonmars" target="_blank" rel="noreferrer">
              <img src={Telegram} alt="Go to Telegram" />
            </a>
          </SocialDiv>
          <LinkDiv>
            <span onClick={toggleSwapModal}>Buy ELON</span>
            <a href="https://docs.dogelonmars.com/Dogelon-frax-docs/Overview">Documentation</a>
            <a href="https://docs.dogelonmars.com/Dogelon-frax-docs/how-to-stake">How to Stake?</a>
          </LinkDiv>
        </FooterContainer>
      </FooterWrapper>
      <SwapModal isOpen={isSwapModalOpen} onClose={toggleSwapModal} />
    </>
  );
}

export default Footer;

// styles

const FooterWrapper = styled.div`
  display: flex;
  justify-content: center;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  background-color: rgba(0, 0, 0, 0.7);
`;

const FooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 32px;
  max-width: 1200px;
  width: 100%;
`;

const SocialDiv = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  & a {
    text-decoration: none;
    transition: 0.2s;
    &:hover {
      opacity: 0.7;
    }
  }
  & img {
    height: 25px;
    with: auto;
    margin-right: 10px;
  }
`;

const LinkDiv = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  & a {
    text-decoration: none;
    font-size: 14px;
    color: #ffffff;
    margin-left: 15px;
    transition: 0.2s;
    &:hover {
      opacity: 0.7;
    }
  }
  & span {
    cursor: pointer;
    text-decoration: none;
    font-size: 14px;
    color: #ffffff;
    margin-left: 15px;
    transition: 0.2s;
    &:hover {
      opacity: 0.7;
    }
  }
`;
